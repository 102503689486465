import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  //fallbackLng: 'en',
  //lng: 'ar',
  resources: {
    en: {
      common: require('./locales/en/common.json'),
      homemain: require('./locales/en/homemain.json'),      
      home: require('./locales/en/home.json'),      
      catgory: require('./locales/en/Category.json'),         
      grade: require('./locales/en/Grade.json'),         
      privateclass: require('./locales/en/PrivateClass.json'),         
      product: require('./locales/en/Product.json'),         
      tempreg: require('./locales/en/TempRegister.json'),         
      footer: require('./locales/en/TheFooter.json'),         
      header: require('./locales/en/TheHeader.json'),   
      askteacher:require('./locales/en/AskTeacher.json'),
      booksolution:require('./locales/en/BookSolution.json'),
      cart:require('./locales/en/Cart.json'),
      checkout:require('./locales/en/Checkout.json'),
      contact:require('./locales/en/Contact.json'),
      courselist:require('./locales/en/Courselist.json'),
      liveclassdetails:require('./locales/en/LiveClassDetails.json'),
      notes:require('./locales/en/Notes.json'),
      prevexam:require('./locales/en/PrevExam.json'),
      productdetails:require('./locales/en/ProductDetails.json'),
      paymentsuccess:require('./locales/en/PaymentSuccess.json'),
      registerform:require('./locales/en/RegisterForm.json'),
      servicedetails:require('./locales/en/Servicedetails.json'),
      subscribed:require('./locales/en/Subscribed.json'),
      subscriberregister:require('./locales/en/SubscriberRegister.json'),
      trialvideos:require('./locales/en/TrialVideos.json'),
      video:require('./locales/en/Video.json'),
      videolibrary:require('./locales/en/VideoLibrary.json')
    },
    ar: {
      common: require('./locales/ar/common.json'),
      homemain: require('./locales/ar/homemain.json'),   
      home: require('./locales/ar/home.json'),         
      catgory: require('./locales/ar/Category.json'),         
      grade: require('./locales/ar/Grade.json'),         
      privateclass: require('./locales/ar/PrivateClass.json'),         
      product: require('./locales/ar/Product.json'),         
      tempreg: require('./locales/ar/TempRegister.json'),         
      footer: require('./locales/ar/TheFooter.json'),         
      header: require('./locales/ar/TheHeader.json'),
      askteacher:require('./locales/ar/AskTeacher.json'),
      booksolution:require('./locales/ar/BookSolution.json'),
      cart:require('./locales/ar/Cart.json'),
      checkout:require('./locales/ar/Checkout.json'),
      contact:require('./locales/ar/Contact.json'),
      courselist:require('./locales/ar/Courselist.json'),
      liveclassdetails:require('./locales/ar/LiveClassDetails.json'),
      notes:require('./locales/ar/Notes.json'),
      prevexam:require('./locales/ar/PrevExam.json'),
      productdetails:require('./locales/ar/ProductDetails.json'),
      paymentsuccess:require('./locales/ar/PaymentSuccess.json'),
      registerform:require('./locales/ar/RegisterForm.json'),
      servicedetails:require('./locales/ar/Servicedetails.json'),
      subscribed:require('./locales/ar/Subscribed.json'),
      subscriberregister:require('./locales/ar/SubscriberRegister.json'),
      trialvideos:require('./locales/ar/TrialVideos.json'),
      video:require('./locales/ar/Video.json'),
      videolibrary:require('./locales/ar/VideoLibrary.json')         
    }
  },
  // ns: ['translations'],
  // defaultNS: 'translations'
});

i18n.languages = ['en', 'ar'];

export default i18n;