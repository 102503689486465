import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { constants } from './constants';
import { userService } from './services';
import 'font-awesome/css/font-awesome.min.css';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './containers/loader';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReactCountryFlag from "react-country-flag"
import { createBrowserHistory } from "history";
//import { Router, Route, browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'));
const Payment = React.lazy(() => import('./views/pages/payment/Payment'));
const HomeMain = React.lazy(() => import('./views/home/HomeMain?v=2'));
const Recorded = React.lazy(() => import('./views/home/Recorded'));



function App() {
  const { t, i18n } = useTranslation();
  const hist = createBrowserHistory();
  const classes = useStyles();
  const Http = new XMLHttpRequest();
  const dispatch = useDispatch();
  const [openCountrySelect, setOpenCountrySelect] = React.useState(false);
  const [countryList, SetCountryList] = useState(null);
  const user = useSelector(state => state.user);
  const [loding, Setloading] = useState(true);
  const [divClass, setDivClass] = useState('');
  useEffect(() => {
    const userlang = localStorage.getItem('spuserlang') ? localStorage.getItem('spuserlang') : 'ar';
    i18n.changeLanguage(userlang);
    changeLangClass(userlang);
    GetCountryList();
    //GetCartList();
    //}
    GetCategoryList();
  }, []);

  useEffect(() => {
    //dispatch({ type: constants.SET_LANGUAGE, value: 'ar' });
    Setloading(user.loading);
  }, [user.loading]);

  useEffect(() => {
    if (user.countryCode) {
      GetCategoryList();
    }
  }, [user.countryCode]);

  useEffect(() => {
    if (user.countryList) {
      getCountryCode();
      SetCountryList(user.countryList);
    }
  }, [user.countryList])


  const GetCountryList = () => {
    userService.Get('SpediaAPI/GetCountry').then(a => {
      if (a.countryList) {
        dispatch({ type: constants.SET_COUNTRY_LIST, value: a.countryList });
      }
    })
  }

  const GetCategoryList = () => {
    dispatch({ type: constants.REQUEST, value: true })
    let userdata = user.userData ? user.userData : null;
    let thisdata = {
      CountryCode: user.countryCode,
      SessionToken: (userdata ? userdata.sessionToken ? userdata.sessionToken : '' : ''),
      StudentID: (userdata ? userdata.studentID ? userdata.studentID : 0 : 0)
    };
    userService.Post(`SpediaAPI/GetCategoryList`, thisdata).then(a => {
      dispatch({ type: constants.REQUEST, value: false })
      if (a.status == "true") {
        const userlang = localStorage.getItem('spuserlang') ? localStorage.getItem('spuserlang') : 'ar';
        let thisuser = {
          userData: { sessionToken: a.sessionToken, studentID: a.studentID },
          countryCode: user.countryCode,
          countryWarning: false,
          lang: userlang
        }
        localStorage.setItem('spuser', JSON.stringify(thisuser));
        dispatch({ type: constants.SET_CATEGORY_LIST, value: a });
        //GetCartList(a.sessionToken);
      }
      else if (a.messageEn == 'authorization failed') {
        localStorage.removeItem('spuser');
        location.reload();
      }
    }).catch(e => {
      let error = e;
    });
  }

  const GetCartList = (sessionToken) => {
    let thisdata = {
      SessionToken: sessionToken,
    };
    if (sessionToken) {
      userService.Post('SpediaAPI/GetCart', thisdata).then(a => {
        if (a.status == "true") {
          if (a.cartItemList) {
            dispatch({ type: constants.SET_CARTDATA, value: a });
          }
        }
        else if (a.messageEn == 'authorization failed') {
          localStorage.removeItem('spuser');
          location.reload();
        }
      })
    }
  }

  const HandleCountryChange = (e) => {
    if (e.target.value) {
      dispatch({ type: constants.SET_COUNTRY_CODE, value: e.target.value });
      setOpenCountrySelect(false);
    }
  }



  function getCountryCode() {
    var bdcApi = "https://api.bigdatacloud.net/data/reverse-geocode-client"

    navigator.geolocation.getCurrentPosition(
      (position) => {
        bdcApi = bdcApi
          + "?latitude=" + position.coords.latitude
          + "&longitude=" + position.coords.longitude
          + "&localityLanguage=en";
        Http.open("GET", bdcApi);
        Http.send();
        Http.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var res = JSON.parse(this.responseText);
            console.log("Country code is :"+res.countryCode);
            if(res.countryCode == "QA")
            {
              window.location.href = "https://qa.spediaapp.com";
            }
            // if (res.countryCode && user.countryList) {
            //   SetContryCode(res.countryCode);
            // }
            // else {
            //   SetCountryWarning();
            // }
          }
        };
      },
      (err) => {
        Http.open("GET", bdcApi);
        Http.send();
        Http.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var res = JSON.parse(this.responseText);
            //$('#code').text(res.countryCode);
            if (res.countryCode && user.countryList) {
              SetContryCode(res.countryCode);
            }
            else {
              SetCountryWarning();
            }
          }
        };
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
  }

  const SetContryCode = (code) => {
    if (user.countryList.some(a => a.countryCode == code)) {
      dispatch({ type: constants.SET_COUNTRY_CODE, value: res.countryCode });
    }
    else if (!user.countryCode) {
      // SetCountryWarning();
      setOpenCountrySelect(true)
    }
  }

  const SetCountryWarning = () => {
    dispatch({ type: constants.SET_COUNTRY_WARNING, value: true });
  }

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    dispatch({ type: constants.SET_LANGUAGE, value: e.target.value });
    localStorage.setItem('spuserlang', e.target.value);
  }
// Step 2: Function to change the class
const changeLangClass = (lang) => {
  console.log('selected_lang:'+lang);
  i18n.changeLanguage(lang);
    dispatch({ type: constants.SET_LANGUAGE, value: lang });
    localStorage.setItem('spuserlang', lang);
  
  const langClass = lang == 'en' ? 'english-E' : '';
  setDivClass(langClass); 
};
  return (
    <React.Fragment>
      <div className={divClass}>
        
        {/* <div className='language-btn'>
          <select onChange={changeLanguage} value={user.lang ? user.lang : 'ar'}>
            <option value='en'>English</option>
            <option value='ar'>Arabic</option>
          </select>
        </div> */}


        {/* <BrowserRouter>
        <ToastContainer position="top-right" newestOnTop={true} transition={Slide} autoClose={5000} />
        <React.Suspense fallback={Loader}>
          <Route path='/' component={TheLayout}>
            <Route path='/login' component={Login} />
            <Route path='/payment' component={Payment} />
            <Route path='/register' component={Register} />
            <Route path='/forgotpassword' component={ForgotPassword} />
          </Route>
         </React.Suspense> 
      </BrowserRouter> */}
        <BrowserRouter>
          <ToastContainer position="top-right" newestOnTop={true} transition={Slide} autoClose={5000} />
          <React.Suspense fallback={Loader}>
            <Switch>
              {/* <Redirect exact from="/" to="/dashboard" /> */}
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/recorded" name="Recorded Page" render={props => <Recorded {...props} />} />
              <Route exact path="/payment" name="Payment Page" render={props => <Payment {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/forgotpassword" name="Forgot" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/forgotpassword" name="Forgot" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/dashboard" name="Dashboard" render={props => <HomeMain {...props} changeLangClass={changeLangClass}/>} index />
              <Route exact path="*" name="Home" render={props => <TheLayout {...props} changeLangClass={changeLangClass}/>} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openCountrySelect}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCountrySelect}>
            <div className={classes.paper}>
              <h2>Please select country.</h2>
              <a href="javaScript:void(0)" onClick={() => setOpenCountrySelect(false)}><i className="fas fa-times"></i></a>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user.countryCode ? user.countryCode : "0"}
                onChange={HandleCountryChange}
                className={classes.country}
              >
                <MenuItem value="0"> Select Country</MenuItem>
                {countryList ?
                  countryList.map((a, index) => {
                    return (
                      <MenuItem value={a.countryCode} key={index}>
                        <ReactCountryFlag countryCode={a.countryCode ? a.countryCode : 'US'} svg />
                        {a.countryNameEn}
                      </MenuItem>
                    )
                  })
                  : null}
              </Select>
              {/* <button onClick={() => setOpenCountrySelect(false)}>Close</button> */}
            </div>
          </Fade>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default App;

function getApi(bdcApi, Http) {
  Http.open("GET", bdcApi);
  Http.send();
  Http.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      var res = JSON.parse(this.responseText);
      //$('#code').text(res.countryCode);
      console.log(res.countryCode);
      return res.countryCode;
    }
  };
}

